import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import { APIProvider } from '@vis.gl/react-google-maps';
import { useStyles } from './Map.styles';
import { MarkerClusterer, Renderer, Cluster, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { configuration } from '../../Core';
import { IEstateListItem } from '../../Core/Api.Web';

import { CircularProgress } from '@mui/material';
import { IRealEstateObjectAlarmStatus } from './IRealEstateObjectAlarmStatus';
import { markerColors } from '../../Theme/Theme';

import WiFiOffIcon from '@mui/icons-material/WifiOffOutlined';
import WiFiIcon from '@mui/icons-material/WifiOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import AlarmIcon from '@mui/icons-material/NotificationsActiveOutlined';
import AlarmErrorIcon from '@mui/icons-material/NotificationImportantOutlined';

export interface IMarker {
    position: { lat: number; lng: number };
    id: string;
    title: string;
    icon?: string | google.maps.Icon | google.maps.Symbol;
    pin: google.maps.marker.PinElementOptions;
    empty?: boolean;
    estate: IEstateListItem;
}

export interface IGroupedMarker {
    id: string;
    markers: IMarker[];
}

export interface MapProps {
    markersData: IMarker[];
    onVisibleMarkers: Function;
}

export const Map = ({ markersData, onVisibleMarkers }: MapProps) => {
    const classes = useStyles();
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [markers, setMarkers] = useState<google.maps.marker.AdvancedMarkerElement[]>([]);

    const [markerInfoWindows, setMarkerInfoWindows] = useState<google.maps.InfoWindow[]>([]);
    const [groupedMarkersData, setGroupedMarkersData] = useState<any[]>([]);
    const [markerCluster, setMarkerCluster] = useState<MarkerClusterer | null>(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const position = { lat: 57.7788946266467, lng: 15.043569999999988 };
    const alarmClusterIconRef = useRef<SVGSVGElement | null>(null);

    // ---------------------------------------------------------------
    // useEffect - mount
    // ---------------------------------------------------------------

    useEffect(() => {
        if (!map) {
            initMap();
        }
        return () => {
            setMarkers([]);
            setMarkerInfoWindows([]);
            setGroupedMarkersData([]);
            setMap(null);
        };
    }, []);

    // ---------------------------------------------------------------
    // initMap - initialising google map
    // ---------------------------------------------------------------

    const initMap = async () => {
        const { Map } = (await google.maps.importLibrary('maps')) as google.maps.MapsLibrary;
        const map = new Map(document.getElementById('mapContainer') as HTMLElement, {
            zoom: 6,
            center: position,
            mapId: configuration.googleMapsMapId,
            fullscreenControl: false,
            gestureHandling: 'greedy',
            minZoom: 4,
            maxZoom: 20,
        });

        map.addListener('click', () => {
            markerInfoWindows.forEach((iw) => {
                iw.close();
            });
        });

        setMap(map);
    };

    // ---------------------------------------------------------------
    // useEffect - markersData - runs when map is loaded
    // ---------------------------------------------------------------

    useEffect(() => {
        if (markersData) {
            const group = Object.entries(groupBy(markersData, (marker: IMarker) => marker.id));
            setGroupedMarkersData(group);
        }
    }, [markersData]);

    // ---------------------------------------------------------------
    // useEffect - map - runs when map is loaded
    // ---------------------------------------------------------------

    useEffect(() => {
        if (map && groupedMarkersData) {
            setLoading(false);
            setMapMarkers();
        }
    }, [map, groupedMarkersData]);

    // ---------------------------------------------------------------
    // setMapMarkers - sets all the markers
    // ---------------------------------------------------------------

    const setMapMarkers = async () => {
        if (map && !markers.length) {
            const { AdvancedMarkerElement, PinElement } = (await google.maps.importLibrary(
                'marker',
            )) as google.maps.MarkerLibrary;

            const markersList = groupedMarkersData.map((md) => {
                const markersOnPosition = md[1] as IMarker[];
                let position = markersOnPosition[0].position;
                const markerEl = new AdvancedMarkerElement({
                    position: position,
                    map,
                    content:
                        markersOnPosition.length > 1
                            ? new PinElement().element
                            : new PinElement(markersOnPosition[0].pin).element,
                    zIndex:
                        Number(google.maps.Marker.MAX_ZINDEX) +
                        markersOnPosition[0].estate.realEstateObjectAlarmStatus.value,
                });
                markerEl.addListener('click', () => {
                    if (markersOnPosition.length === 1) {
                        navigate(
                            `/estate/${markersOnPosition[0].estate.routeName}?estateId=${markersOnPosition[0].estate.id}&estateRouteName=${markersOnPosition[0].estate.routeName}`,
                        );
                        markerInfoWindows.forEach((iw) => {
                            iw.close();
                        });
                    } else {
                        markerInfoWindows.forEach((iw) => {
                            iw.close();
                        });
                        markerInfoWindow.open({
                            anchor: markerEl,
                            map,
                        });
                    }
                });

                const markerInfoWindow = getInfoWindow(markersOnPosition[0].title, markersOnPosition);
                markerInfoWindows.push(markerInfoWindow);
                setMarkerInfoWindows([...markerInfoWindows]);

                markerEl.content?.addEventListener('mouseenter', (e) => {
                    markerInfoWindows.forEach((iw) => {
                        iw.close();
                    });
                    markerInfoWindow.open({
                        anchor: markerEl,
                        map,
                    });
                });

                markerEl.content?.addEventListener('mouseleave', () => {
                    markersOnPosition.length === 1 && markerInfoWindow.close();
                });

                return markerEl;
            });

            setMarkers(markersList);
            setBounds();
        }
    };

    // -----------------------------------------------------------------------------
    // setBounds - sets bounds for the map markers on start up
    // -----------------------------------------------------------------------------

    const setBounds = () => {
        if (map && markersData) {
            const bounds = new window.google.maps.LatLngBounds();
            markersData.forEach((marker) => {
                bounds.extend(new window.google.maps.LatLng(marker.position));
            });
            map.fitBounds(bounds);
            map.setZoom(6);
        }
    };

    const checkVisibleMarkers = () => {
        if (map && markers) {
            const bounds = map.getBounds();
            const markersVisible: google.maps.marker.AdvancedMarkerElement[] = [];
            for (var i = 0; i < markers.length; i++) {
                var marker = markers[i];

                if (bounds && marker.position) {
                    if (bounds?.contains(marker.position) === true) {
                        markersVisible.push(marker);
                    }
                }
            }

            onVisibleMarkers(markersVisible.length ? markersVisible : markers);
        }
    };

    // ---------------------------------------------------------------
    // useEffect - markers -     runs when alla markers is created
    // ---------------------------------------------------------------

    useEffect(() => {
        if (markers && markersData && map && !markerCluster) {
            google.maps.event.addListener(map, 'idle', () => {
                markerInfoWindows.forEach((iw) => {
                    iw.close();
                });
                checkVisibleMarkers();
            });

            const renderer: Renderer = {
                render: ({ count, position, markers }: Cluster, stats) => {
                    let clusterEl = null;
                    let clusterInfoWindow: google.maps.InfoWindow | null = null;
                    if (markers) {
                        const clusterMarkers: google.maps.marker.AdvancedMarkerElement[] =
                            markers as google.maps.marker.AdvancedMarkerElement[];

                        const clusteredMarkersData = markersData.filter((markerData) =>
                            clusterMarkers.some(
                                (clusterMarker) =>
                                    markerData.position.lat === clusterMarker.position?.lat &&
                                    markerData.position.lng === clusterMarker.position?.lng,
                            ),
                        );
                        const clusterColor = getClusterColor(clusteredMarkersData);
                        const scalePercent = count / 300;

                        clusterEl = document.createElement('div');
                        clusterEl.classList.add(classes.clusterMarker);
                        clusterEl.style.width = `${40 + scalePercent * 10}px`;
                        clusterEl.style.height = `${40 + scalePercent * 10}px`;
                        clusterEl.style.backgroundColor = `${clusterColor.background}D9`;
                        clusterEl.style.border = `3px solid ${clusterColor.borderColor}80`;
                        clusterEl.style.boxShadow = `0px 0px 9px 4px ${clusterColor.borderColor}80`;

                        const clusterIcon = document.createElement('div');
                        clusterIcon.classList.add(classes.clusterMarkerIcon);
                        clusterIcon.innerHTML = getClusterIcon(clusteredMarkersData);

                        const clusterText = document.createElement('p');
                        clusterText.classList.add(classes.clusterMarkerText);
                        clusterText.innerText = `${count}`;
                        //clusterEl.appendChild(clusterIcon);
                        clusterEl.appendChild(clusterText);

                        clusterInfoWindow = getClusterInfo(clusteredMarkersData);
                    }

                    const clusterMarkerElement = new google.maps.marker.AdvancedMarkerElement({
                        map,
                        position,
                        content: clusterEl,
                        zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                    });

                    let mouseOverInfo = false;
                    if (clusterInfoWindow) {
                        clusterMarkerElement.content?.addEventListener('mouseenter', () => {
                            clusterInfoWindow?.open({
                                anchor: clusterMarkerElement,
                                map,
                                shouldFocus: true,
                            });
                        });

                        clusterMarkerElement.content?.addEventListener('mouseleave', () => {
                            clusterInfoWindow?.close();
                        });
                    }

                    return clusterMarkerElement;
                },
            };

            const cluster = new MarkerClusterer({
                markers,
                map,
                renderer,
                algorithm: new SuperClusterAlgorithm({ radius: 100, minPoints: 3 }),
            });

            cluster.addListener('click', (cluster: MarkerClusterer) => {
                markerInfoWindows.forEach((iw) => {
                    iw.close();
                });
            });

            setMarkerCluster(cluster);
            //  checkVisibleMarkers(map, markers);
        }
    }, [markers]);

    // -----------------------------------------------------------------------------
    // getInfoWindow  - gets the info window for markers when hovering
    // -----------------------------------------------------------------------------

    const getInfoWindow = (text: string, markers: IMarker[]) => {
        const sortedMarkers = markers.sort(
            (a, b) => b.estate.realEstateObjectAlarmStatus.value - a.estate.realEstateObjectAlarmStatus.value,
        );
        const multi = markers.length > 1;
        const infoWindowContent = document.createElement('div');
        infoWindowContent.classList.add(classes.markerInfoWindow);
        if (multi) {
            infoWindowContent.classList.add('multi');
        }
        const infoWindowList = document.createElement('ul');
        infoWindowList.classList.add(classes.infoWindowList);
        infoWindowContent.appendChild(infoWindowList);
        sortedMarkers.forEach((marker) => {
            const infoWindowListItem = document.createElement('li');
            infoWindowListItem.classList.add(classes.infoWindowListItem);
            infoWindowList.appendChild(infoWindowListItem);

            const infoWindowIcon = document.createElement('div');
            infoWindowIcon.classList.add(classes.infoWindowListItemIcon);
            infoWindowIcon.innerHTML = getIconByStatus(marker.estate.realEstateObjectAlarmStatus.value).icon;

            const infoWindowText = document.createElement('span');

            infoWindowText.innerText = text;
            infoWindowText.style.whiteSpace = 'pre-wrap';

            infoWindowListItem.appendChild(infoWindowIcon);
            infoWindowListItem.appendChild(infoWindowText);

            infoWindowListItem.addEventListener('click', () => {
                navigate(
                    `/estate/${marker.estate.routeName}?estateId=${marker.estate.id}&estateRouteName=${marker.estate.routeName}`,
                );
                markerInfoWindows.forEach((iw) => {
                    iw.close();
                });
            });
        });

        return new google.maps.InfoWindow({
            content: infoWindowContent,
            ariaLabel: 'info window',
            disableAutoPan: true,
        });
    };

    const getIconByStatus = (status: number) => {
        switch (status) {
            case IRealEstateObjectAlarmStatus.AlarmSourceProblem:
                return {
                    icon: getIcon(
                        <AlarmErrorIcon
                            fontSize="small"
                            style={{ color: markerColors.AlarmSourceProblem.background }}
                        />,
                    ),
                    color: markerColors.AlarmSourceProblem.background,
                };
                break;
            case IRealEstateObjectAlarmStatus.Alarm:
                return {
                    icon: getIcon(<AlarmIcon fontSize="small" style={{ color: markerColors.Alarm.background }} />),
                    color: markerColors.Alarm.background,
                };
                break;
            case IRealEstateObjectAlarmStatus.Broken:
                return {
                    icon: getIcon(<ErrorIcon fontSize="small" style={{ color: markerColors.Broken.background }} />),
                    color: markerColors.Broken.background,
                };
                break;
            case IRealEstateObjectAlarmStatus.Connected:
                return {
                    icon: getIcon(<WiFiIcon fontSize="small" style={{ color: markerColors.Connected.background }} />),
                    color: markerColors.Connected.background,
                };

                break;
            case IRealEstateObjectAlarmStatus.NotConnected:
            default:
                return {
                    icon: getIcon(
                        <WiFiOffIcon fontSize="small" style={{ color: markerColors.NotConnected.background }} />,
                    ),
                    color: markerColors.NotConnected.background,
                };
        }
    };

    // -----------------------------------------------------------------------------
    // getClusterInfo - gets the cluster info window for clusters when hovering
    // -----------------------------------------------------------------------------

    const getClusterInfo = (clusterMarkerData: IMarker[]) => {
        const groups = Object.entries(
            groupBy(clusterMarkerData, (c: IMarker) => c.estate.realEstateObjectAlarmStatus.value),
        );

        const clusterInfoList = document.createElement('ul');
        clusterInfoList.classList.add(classes.clusterMarkerList);

        groups.forEach((group) => {
            let iconSVG;
            let color;
            switch (Number(group[0])) {
                case IRealEstateObjectAlarmStatus.AlarmSourceProblem:
                    iconSVG = getIcon(
                        <AlarmErrorIcon
                            fontSize="small"
                            style={{ color: markerColors.AlarmSourceProblem.background }}
                        />,
                    );
                    color = markerColors.AlarmSourceProblem.background;
                    break;
                case IRealEstateObjectAlarmStatus.Alarm:
                    iconSVG = getIcon(<AlarmIcon fontSize="small" style={{ color: markerColors.Alarm.background }} />);
                    color = markerColors.Alarm.background;
                    break;
                case IRealEstateObjectAlarmStatus.Broken:
                    iconSVG = getIcon(<ErrorIcon fontSize="small" style={{ color: markerColors.Broken.background }} />);
                    color = markerColors.Broken.background;
                    break;
                case IRealEstateObjectAlarmStatus.Connected:
                    iconSVG = getIcon(
                        <WiFiIcon fontSize="small" style={{ color: markerColors.Connected.background }} />,
                    );
                    color = markerColors.Connected.background;
                    break;
                case IRealEstateObjectAlarmStatus.NotConnected:
                default:
                    iconSVG = getIcon(
                        <WiFiOffIcon fontSize="small" style={{ color: markerColors.NotConnected.background }} />,
                    );
                    color = markerColors.NotConnected.background;
            }

            const amount = group[1] as any[];

            const clusterInfoListItem = document.createElement('li');
            clusterInfoListItem.classList.add(classes.clusterMarkerListItem);
            clusterInfoList.appendChild(clusterInfoListItem);

            const clusterInfoListItemImg = document.createElement('div');
            clusterInfoListItemImg.classList.add(classes.clusterMarkerListItemImg);
            clusterInfoListItemImg.innerHTML = iconSVG;

            const clusterInfoListItemAmount = document.createElement('div');
            clusterInfoListItemAmount.style.color = color;
            clusterInfoListItemAmount.innerText = `${amount.length}`;
            clusterInfoListItem.appendChild(clusterInfoListItemImg);
            clusterInfoListItem.appendChild(clusterInfoListItemAmount);
        });

        return new google.maps.InfoWindow({
            content: clusterInfoList,
            ariaLabel: 'info window',
        });
    };

    const groupBy = (x: any, f: any) => x.reduce((a: any, b: any, i: any) => ((a[f(b, i, x)] ||= []).push(b), a), {});

    // -----------------------------------------------------------------------------
    // getClusterColor - gets the color for the cluster blob
    // -----------------------------------------------------------------------------

    const getClusterColor = (clusterMarkerData: IMarker[]) => {
        const anyNotConnected: boolean = clusterMarkerData.some(
            (cmd) => cmd.estate.realEstateObjectAlarmStatus.value === IRealEstateObjectAlarmStatus.NotConnected,
        );
        const anyConnected: boolean = clusterMarkerData.some(
            (cmd) => cmd.estate.realEstateObjectAlarmStatus.value === IRealEstateObjectAlarmStatus.Connected,
        );
        const anyBroken: boolean = clusterMarkerData.some(
            (cmd) => cmd.estate.realEstateObjectAlarmStatus.value === IRealEstateObjectAlarmStatus.Broken,
        );
        const anyAlarm: boolean = clusterMarkerData.some(
            (cmd) => cmd.estate.realEstateObjectAlarmStatus.value === IRealEstateObjectAlarmStatus.Alarm,
        );
        const anyAlarmSourceProblem: boolean = clusterMarkerData.some(
            (cmd) => cmd.estate.realEstateObjectAlarmStatus.value === IRealEstateObjectAlarmStatus.AlarmSourceProblem,
        );

        if (anyAlarm) {
            return markerColors.Alarm;
        } else if (anyBroken) {
            return markerColors.Broken;
        } else if (anyAlarmSourceProblem) {
            return markerColors.AlarmSourceProblem;
        } else if (anyConnected) {
            return markerColors.Connected;
        } else if (anyNotConnected) {
            return markerColors.NotConnected;
        } else {
            return markerColors.NotConnected;
        }
    };

    // -----------------------------------------------------------------------------
    // getClusterIcon - gets the symbol icon for the cluster blob
    // -----------------------------------------------------------------------------

    const getClusterIcon = (clusterMarkerData: IMarker[]) => {
        const anyNotConnected: boolean = clusterMarkerData.some(
            (cmd) => cmd.estate.realEstateObjectAlarmStatus.value === IRealEstateObjectAlarmStatus.NotConnected,
        );
        const anyConnected: boolean = clusterMarkerData.some(
            (cmd) => cmd.estate.realEstateObjectAlarmStatus.value === IRealEstateObjectAlarmStatus.Connected,
        );
        const anyBroken: boolean = clusterMarkerData.some(
            (cmd) => cmd.estate.realEstateObjectAlarmStatus.value === IRealEstateObjectAlarmStatus.Broken,
        );
        const anyAlarm: boolean = clusterMarkerData.some(
            (cmd) => cmd.estate.realEstateObjectAlarmStatus.value === IRealEstateObjectAlarmStatus.Alarm,
        );
        const anyAlarmSourceProblem: boolean = clusterMarkerData.some(
            (cmd) => cmd.estate.realEstateObjectAlarmStatus.value === IRealEstateObjectAlarmStatus.AlarmSourceProblem,
        );

        if (anyAlarmSourceProblem) {
            return getIcon(
                <AlarmErrorIcon fontSize="medium" style={{ color: markerColors.AlarmSourceProblem.borderColor }} />,
            );
        } else if (anyAlarm) {
            return getIcon(<AlarmIcon fontSize="medium" style={{ color: markerColors.Alarm.borderColor }} />);
        } else if (anyBroken) {
            return getIcon(<ErrorIcon fontSize="medium" style={{ color: markerColors.Broken.borderColor }} />);
        } else if (anyConnected) {
            return getIcon(<WiFiIcon fontSize="medium" style={{ color: markerColors.Connected.borderColor }} />);
        } else if (anyNotConnected) {
            return getIcon(<WiFiOffIcon fontSize="medium" style={{ color: markerColors.NotConnected.borderColor }} />);
        } else {
            return getIcon(<WiFiOffIcon fontSize="medium" style={{ color: markerColors.NotConnected.borderColor }} />);
        }
    };

    function getIcon(iconNode: JSX.Element): string {
        const iconString = ReactDOMServer.renderToString(iconNode);
        return iconString;
    }

    return (
        <>
            {loading && (
                <div className={classes.loadingContainer}>
                    <CircularProgress disableShrink />
                </div>
            )}

            <APIProvider apiKey={configuration.googleMapsApiKey!}>
                <div style={{ height: '100%', width: '100%' }}>
                    <div id="mapContainer" style={{ height: '100%', width: '100%' }}></div>
                </div>
            </APIProvider>
        </>
    );
};
